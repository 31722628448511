import React, { useMemo } from 'react'
import {
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
  AlertDescription,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react'
import {
  As,
  HubResponsiveValue,
  HubStyleObject,
  StandardSizes,
  forwardRef,
  injectTheme,
  IconColors,
} from '@hub/design-system-base'
import { Box } from '@hub/box'
import {
  AlertMediumIcon,
  InfoConciergeMediumIcon,
  TickCircleMediumIcon,
} from '@hub/icon'
import { Link } from '@hub/link'
import { theme } from '@hub/design-system-base/src/theme'
import { alertTheme } from './theme'

injectTheme('Alert', alertTheme)

type AlertStatus = 'success' | 'error' | 'info' | 'warning'

type AlertIconProps = {
  status: AlertStatus
  color: IconColors
}

const AlertIcon = ({ status, color }: AlertIconProps): React.ReactElement => {
  const alertIcon = useMemo(() => {
    switch (status) {
      case 'success':
        return <TickCircleMediumIcon boxSize="size-icon-md" color={color} />
      case 'error':
        return <AlertMediumIcon boxSize="size-icon-md" color={color} />
      case 'info':
        return <InfoConciergeMediumIcon boxSize="size-icon-md" color={color} />
      case 'warning':
        return <AlertMediumIcon boxSize="size-icon-md" color={color} />
    }
  }, [color, status])

  return (
    <Box
      marginLeft="spacing-xs"
      marginRight="spacing-md"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      {alertIcon}
    </Box>
  )
}

export type AlertProps = ChakraAlertProps & {
  message: string
  title?: string
  link?: { href: string; label: string }
  status?: AlertStatus
  icon?: boolean
  onClose?: () => void
  width?: HubResponsiveValue<StandardSizes>
  sx?: HubStyleObject
  as?: As
  className?: string
}

export const Alert = forwardRef<AlertProps, typeof ChakraAlert>(
  (
    {
      message,
      status = 'success',
      title,
      link,
      icon,
      onClose,
      width,
      sx,
      as,
      className,
      ...props
    },
    ref
  ) => {
    const backgroundColor = useMemo(() => {
      switch (status) {
        case 'success':
          return theme.colors.surfaceSuccess
        case 'error':
          return theme.colors.surfaceError
        case 'info':
          return theme.colors.surfaceInfo
        case 'warning':
          return theme.colors.surfaceWarning
      }
    }, [status])

    return (
      <ChakraAlert
        status={status}
        ref={ref}
        sx={sx}
        as={as}
        className={className}
        width={width}
        padding="spacing-md"
        backgroundColor={backgroundColor}
        color={theme.colors.textPrimary}
        data-test-id={`alert-${status}`}
        {...props}
      >
        <Box display="flex" alignItems="center" className="alert-content">
          {icon && (
            <AlertIcon status={status} color={theme.colors.iconPrimary} />
          )}
          <Box>
            {title && (
              <AlertTitle sx={{ lineHeight: 'base' }}>{title}</AlertTitle>
            )}
            <AlertDescription>
              {message}
              {link && (
                <Link href={link.href} isExternal hasUnderline target="_blank">
                  {link.label}
                </Link>
              )}
            </AlertDescription>
          </Box>
        </Box>
        {onClose && (
          <CloseButton
            onClick={onClose}
            sx={{ color: theme.colors.iconPrimary, marginLeft: 'spacing-md' }}
          />
        )}
      </ChakraAlert>
    )
  }
)

Alert.displayName = 'Alert'
