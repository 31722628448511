import { useQuery } from '@apollo/client'
import React, { ReactNode, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { Box } from '@hub/box'
import { CoreContainer } from '@hub/core-container'
import { useGetHrefContext } from '@scentregroup/shared/context'
import { useCountry } from '@scentregroup/shared/country'
import { GlobalStaticDataDocument } from '@scentregroup/shared/graphql'
import Layout from '@scentregroup/shared/hub-components/layout'
import { useRouter } from 'next/router'

import { useTopStripProps } from '@scentregroup/shared/hub-components/top-strip'
import { ErrorBoundary } from 'react-error-boundary'
import { NationalStaticData } from '@scentregroup/shared/helpers/fetch-header-static-data'
import { CONFIG_KEYS, getClientConfigBool } from '@scentregroup/shared/config'
import { userAtom } from '../../../lib/atoms/user-info'
import {
  ORDERS,
  PARKING,
  PROFILE,
  PREFERENCES,
  incBaseAccount,
  PREFERRED_CENTRE,
} from '../../../lib/paths'
import useNextLoadNotification from '../../../lib/use-next-load-notification'
import useNotification from '../../../lib/use-notification'
import Notification from '../../notification'
import ErrorPage from '../../error-page'

type Props = {
  pageId: string
  children: ReactNode
  maxWidth?: React.ComponentProps<typeof CoreContainer>['maxWidth']
  taskMode?: boolean
  backLink?: string
  onClickBack?: React.MouseEventHandler
}

const AccountLayout: React.FC<React.PropsWithChildren<Props>> = ({
  pageId,
  children,
  maxWidth,
  taskMode = false,
  backLink,
  onClickBack,
}: Props) => {
  const { closeNotification } = useNotification()
  const { openNextLoadNotification } = useNextLoadNotification()
  const router = useRouter()

  const country = useCountry()
  const canonical = useGetHrefContext()()

  const { data: globalStaticData } = useQuery(GlobalStaticDataDocument, {
    context: {
      version: 2,
    },
    variables: { country },
  })
  const topStripProps = useTopStripProps(country)

  const navigationMenu =
    globalStaticData?.nationalHomepage?.items?.[0]?.navigationMenu
  const isNavLegacy = Array.isArray(navigationMenu)

  let giftCardsNavObject = globalStaticData?.giftCards?.items?.[0]
    ? globalStaticData?.giftCards?.items?.[0]
    : undefined

  const { data: user } = useRecoilValue(userAtom)
  const userHasMarketplaceOrders = user?.hasMarketplaceOrders || false

  // Support the new giftcard nav JSON structure
  if (
    giftCardsNavObject &&
    giftCardsNavObject.navigationMenu &&
    !Array.isArray(giftCardsNavObject.navigationMenu) &&
    giftCardsNavObject.navigationMenu.nav &&
    Array.isArray(giftCardsNavObject.navigationMenu.nav)
  ) {
    giftCardsNavObject = {
      navigationMenu: giftCardsNavObject.navigationMenu.nav,
      title: giftCardsNavObject.title,
      sys: giftCardsNavObject.sys,
    }
  }

  const isPreferredCentreEnabled = getClientConfigBool(
    CONFIG_KEYS.NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE
  )

  const accountHeaderData = {
    giftCards: giftCardsNavObject?.navigationMenu,
    navigationMenu: isNavLegacy ? navigationMenu : navigationMenu?.nav,
    centres: globalStaticData?.centres?.items ?? [],
    primaryLinks: [
      { label: 'Account', url: incBaseAccount(`/`) },
      { label: 'Profile', url: incBaseAccount(`/${PROFILE}`) },
      ...(isPreferredCentreEnabled
        ? [
            {
              label: 'Preferred centre',
              url: incBaseAccount(`/${PREFERRED_CENTRE}`),
            },
          ]
        : []),
      userHasMarketplaceOrders && {
        label: 'Orders',
        url: incBaseAccount(`/${ORDERS}`),
      },
      {
        label: 'Parking',
        url: incBaseAccount(`/${PARKING}`),
      },
      {
        label: 'Preferences',
        url: incBaseAccount(`/${PREFERENCES}`),
      },
    ],
    tertiaryLinks: [],
  } as NationalStaticData

  // SUGG Confirm these and update src/shared/helpers/get-default-national-metadata.ts
  const metaData = {
    title: `Westfield ${country} - View and Update your account details`,
    description:
      'Westfield is your one-stop destination for shopping, leisure and entertainment. Discover 1000s of popular retailers in fashion, home, decor and more.',
    canonical,
  }

  useEffect(() => {
    const handleRouteChangeStart = (): void => {
      // Clear any current notifications from previous route
      closeNotification()
    }
    const handleRouteChangeComplete = (): void => {
      // Load new next load notifications set by previous route
      openNextLoadNotification()
    }
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    // unsubscribe when unmounted
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router, closeNotification, openNextLoadNotification])

  return (
    <>
      <Layout
        country={country}
        topStrip={topStripProps}
        centre={undefined}
        header={{
          variant: 'account',
          data: accountHeaderData,
        }}
        metaData={metaData}
        taskMode={taskMode}
        backLink={backLink}
        showCVPStrip={!taskMode}
        showRobotsMetaTag={false}
        onClickBack={onClickBack}
      >
        <ErrorBoundary
          fallbackRender={({ error }) => <ErrorPage error={error} />}
        >
          <Box id={pageId}>
            <CoreContainer
              maxWidth={maxWidth || (taskMode ? '465px' : '720px')}
            >
              <Notification />
              {children}
            </CoreContainer>
          </Box>
        </ErrorBoundary>
      </Layout>
    </>
  )
}

export default AccountLayout
